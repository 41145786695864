.navigation-clean {
  background:#fff;
  padding-top:.75rem;
  padding-bottom:.75rem;
  color:#333;
  border-radius:0;
  box-shadow:none;
  border:none;
  margin-bottom:0;
}

@media (min-width:768px) {
  .navigation-clean {
    padding-top:1rem;
    padding-bottom:1rem;
  }
}

.navigation-clean .navbar-brand {
  font-weight:bold;
  color:inherit;
}

.navigation-clean .navbar-brand:hover {
  color:#222;
}

.navigation-clean .navbar-toggler {
  border-color:#ddd;
}

.navigation-clean .navbar-toggler:hover, .navigation-clean .navbar-toggler:focus {
  background:none;
}

.navigation-clean .navbar-toggler {
  color:#888;
}

.navigation-clean .navbar-collapse, .navigation-clean .form-inline {
  border-top-color:#ddd;
}

.navigation-clean.navbar-light .navbar-nav .nav-link.active, .navigation-clean.navbar-light .navbar-nav .nav-link.active:focus, .navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
  color:#8f8f8f;
  box-shadow:none;
  background:none;
  pointer-events:none;
}

.navigation-clean.navbar .navbar-nav .nav-link {
  padding-left:18px;
  padding-right:18px;
}

.navigation-clean.navbar-light .navbar-nav .nav-link {
  color:#465765;
}

.navigation-clean.navbar-light .navbar-nav .nav-link:focus, .navigation-clean.navbar-light .navbar-nav .nav-link:hover {
  color:#37434d !important;
  background-color:transparent;
}

.navigation-clean .navbar-nav > li > .dropdown-menu {
  margin-top:-5px;
  box-shadow:none;
  background-color:#fff;
  border-radius:2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item {
  line-height:2;
  color:#37434d;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
  background:#eee;
  color:inherit;
}

